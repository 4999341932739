<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader
          :title="id ? 'Update Permission' : 'Create New Permission'"
        />
        <div>
          <md-button :to="{ name: 'Permission Management' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate @submit.prevent="validatePermission">
          <div class="d-flex">
            <div class="form-check" style="display: flex; align-items: center">
              <md-radio v-model="mode" :value="'Basic'" id="check-all">
              </md-radio>
              <label for="check-all">Basic Permission</label>
            </div>
            <div class="form-check" style="display: flex; align-items: center">
              <md-radio v-model="mode" :value="'CRUD'" id="check-all">
              </md-radio>
              <label for="check-all"> CRUD Permission</label>
            </div>
          </div>

          <section v-if="mode == 'Basic'" class="basic">
            <md-field :class="getValidationClass('display_name')">
              <label>Display Name (Human Readable)</label>
              <md-input v-model="form.display_name" required></md-input>
              <span class="md-error" v-if="!$v.form.display_name.required"
                >Display name is required</span
              >
            </md-field>

            <md-field :class="getValidationClass('description')">
              <label>Description</label>
              <md-textarea v-model="form.description" required></md-textarea>
              <span class="md-error" v-if="!$v.form.description.required"
                >Description is required</span
              >
            </md-field>
          </section>
          <section v-else class="crud">
            <md-field :class="getValidationClass('resource_name')">
              <label>Resource Name</label>
              <md-input v-model="resource_name" required></md-input>
            </md-field>

            <div class="row">
              <div class="col-md-4">
                <div
                  class="form-check"
                  style="display: flex; align-items: center"
                >
                  <md-checkbox
                    checked
                    v-model="crud_selected"
                    type="checkbox"
                    id="view-any"
                    name="crud_selected[]"
                    value="viewAny"
                  >
                  </md-checkbox>
                  <label for="view-any"> View Any</label>
                </div>
                <div
                  class="form-check"
                  style="display: flex; align-items: center"
                >
                  <md-checkbox
                    checked
                    v-model="crud_selected"
                    type="checkbox"
                    name="crud_selected[]"
                    id="view"
                    value="view"
                  >
                  </md-checkbox>
                  <label for="view"> View</label>
                </div>
                <div
                  class="form-check"
                  style="display: flex; align-items: center"
                >
                  <md-checkbox
                    checked
                    v-model="crud_selected"
                    type="checkbox"
                    name="crud_selected[]"
                    value="create"
                    id="create"
                  >
                  </md-checkbox>
                  <label for="create"> Check All</label>
                </div>

                <div
                  class="form-check"
                  style="display: flex; align-items: center"
                >
                  <md-checkbox
                    checked
                    v-model="crud_selected"
                    type="checkbox"
                    name="crud_selected[]"
                    value="update"
                    id="update"
                  >
                  </md-checkbox>
                  <label for="Update"> Update</label>
                </div>
                <div
                  class="form-check"
                  style="display: flex; align-items: center"
                >
                  <md-checkbox
                    checked
                    v-model="crud_selected"
                    type="checkbox"
                    name="crud_selected[]"
                    value="delete"
                    id="delete"
                  >
                  </md-checkbox>
                  <label for="check-all"> Delete</label>
                </div>
              </div>
              <div class="col-md-8">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Slug</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody style="">
                    <tr v-for="(perm, index) in crud_selected" :key="index">
                      <td>{{ crudeRender(perm, "name") }}</td>
                      <td>
                        <span class="badge badge-pill badge-warning">{{
                          crudeRender(perm, "slug")
                        }}</span>
                      </td>
                      <td>{{ crudeRender(perm, "description") }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>

          <mdc-button :loading="loading">{{
            id ? "Update Permission" : "Create Permission"
          }}</mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("user");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {
      permissions: [],
      guard_name: "web",
    },
    resource_name: "",
    checked: "",
    loading: false,
    permissions: [],
    mode: "Basic", // CRUD
    crud_selected: ["viewAny", "view", "create", "read", "update", "delete"],
    crudTrack: [],
  }),
  validations: {
    form: {
      display_name: {
        // required,
      },
      description: {
        // required,
      },
    },
  },
  watch: {
    resource_name(val) {
      let data = this.setData(val);
      this.cruds.map((c) => this.setData(val));
    },
  },
  methods: {
    ...mapActions(["createPermission", "updatePermission", "getFormData"]),
    crudeRender: function (item, type) {
      var data = null;
      switch (type) {
        case "name":
          data = `${item.substr(0, 1).toUpperCase()}${item.substr(
            1
          )} ${this.resource_name
            .substr(0, 1)
            .toUpperCase()}${this.resource_name.substr(1)}`;
          break;
        case "slug":
          let slug = `${item.toLowerCase()} ${this.resource_name.toLowerCase()}`;
          data = slug.trim().replace(/\s/g, "_");
          break;
        default:
          data = `Allow a user to ${item.toUpperCase()} ${this.resource_name.toLowerCase()} `;
      }

      return data;
    },
    async validatePermission() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };
        data.permission_type = this.mode.toLowerCase();

        if (data.permission_type == "crud") {
          data.resource = this.resource_name;
          data.crud_selected = this.crud_selected;
        }

        try {
          this.loading = true;
          let call = this.id
            ? this.updatePermission(data)
            : this.createPermission(data);
          let msg = this.id ? "Permission Updated" : "Permission Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "Permission Management" });
          this.loading = false;
        } catch (err) {
          console.log(err);
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    async getData() {
      const permissions = await this.getFormData();
      this.permissions = permissions;
    },
    setData(e) {
      return {
        id: e,
        name: `${e} ${this.resource_name}`,
        slug: `${e.toLowerCase()}_${this.resource_name.toLowerCase()}`,
        description: `Allow a user to ${e.toUpperCase()} ${this.resource_name}`,
      };
    },
  },
  mounted() {
    // this.getData();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    cruds() {
      return [];
    },
  },
};
</script>

<style scoped lang="scss">
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}
</style>
